import _isFunction from 'lodash/isFunction';
import _identity from 'lodash/identity';
import { buildQuery } from 'helpers';
import { getCookie } from 'helpers';

import Http from '../http';
import { load, hasLoaded, addError, clearErrorsByPattern } from './actions';

const rethrow = (err) => {
  throw err;
};

export function withDefaultHeaders(headers) {
  const xsrfCookie = getCookie('XSRF-TOKEN');
  if (xsrfCookie) {
    headers['X-XSRF-TOKEN'] = xsrfCookie;
  }
  return headers;
}

const USER_PATH = process.env.USER_PATH || '/user';
const ERROR_CODES = [401, 403];

const getErrorBody = (error) =>
  new Promise((resolve) => {
    const resolveEmpty = () => resolve({});

    if (_isFunction(error.json)) {
      // Resolve with json body, else resolve with empty object.
      error.json().then(resolve).catch(resolveEmpty);
    } else {
      resolveEmpty();
    }
  });

export const asyncActionDefaultErrorHandler = (err, body) => {
  const { status } = err || {};

  if (ERROR_CODES.indexOf(status) > -1) {
    let params = {
      originalUrl: encodeURIComponent(window.location.href.replace(window.location.origin, '')),
    };

    if (body) {
      params.error = body.status;
    }

    window.location.href = `${USER_PATH}/logout${buildQuery(params)}`;
  }

  rethrow(err);
};

const asyncAction = (config) => (dispatch) => {
  const {
    success,
    error,
    onRequest,
    finish,
    // Pattern to clear errors with. Default: key
    clearErrorsPattern,
    ...serializableConfig
  } = config;
  const {
    key,
    url,
    method = 'GET',
    body = {},
    headers = {},
    timeout = 0,
    // Clear errors before proceeding
    clearErrors = true,
    // When false will not call default error handler. Should be true for majority of use cases
    useDefaultErrorHandler = true,
    type,
    useUnparsedBody = false,
  } = serializableConfig;
  // Ensure that the callback methods have access to the dispatch function via `this.dispatch`
  const callbackBinding = { dispatch };
  let onSuccess = _identity;
  let onError;

  if (_isFunction(success)) {
    onSuccess = success.bind(callbackBinding);
  }

  if (_isFunction(error)) {
    onError = error.bind(callbackBinding);
  }

  if (clearErrors) {
    // App may fall into a case where there are multiple errors of given key.
    // Clear errors by specified pattern or all (including stale) errors of action key.
    dispatch(clearErrorsByPattern(clearErrorsPattern || `^${key}$`));
  }

  // Generic loading action
  dispatch(load(key, serializableConfig));

  // Callback function to execute when the request is made.
  if (_isFunction(onRequest)) {
    onRequest.call(callbackBinding);
  }

  return (
    Http.request(url, method, body, withDefaultHeaders(headers), timeout, type, useUnparsedBody)
      // Execute the success handler
      .then(onSuccess)

      // Handle errors
      .catch((reqError) => {
        // In case of being offline or losing connection reqError is undefined.
        // Default status to 500 and empty error body.
        const err = reqError || { status: 500 };
        const status = err.status || 500;

        return getErrorBody(err).then((errBody) => {
          // Generic error action
          dispatch(addError(key, { status, body: errBody, config: serializableConfig }));

          // call error handler. Error body should already be available in store.
          if (onError) {
            onError(err, errBody);
          }

          useDefaultErrorHandler
            ? asyncActionDefaultErrorHandler(err, errBody)
            : rethrow(err, errBody);
        });
      })

      // Log
      .catch((err) => {
        console.error('Error @ asyncAction - ', err, key);

        rethrow(err);
      })

      // Finish
      .finally(() => {
        dispatch(hasLoaded(key));

        if (_isFunction(finish)) {
          finish.call(callbackBinding);
        }
      })
  );
};

export default asyncAction;

import { buildQuery } from 'helpers';
import isSuccessStatus from './isSuccessStatus';

const defaultHeaders = () => ({
  'content-type': 'application/json',
});

const Http = {
  request(url, method, body = {}, headers, timeout = 0, type, useUnparsedBody = false) {
    let hasRejected = false;

    const defaultRequestHeaders = defaultHeaders();
    if (type === 'form-post' || method === 'HEAD') {
      delete defaultRequestHeaders['content-type'];
    }
    const currentHeaders = Object.assign({}, defaultRequestHeaders, headers || {});
    let responseStatus;

    return new Promise((resolve, reject) => {
      const data = {
        method,
        headers: currentHeaders,
        credentials: 'include',
      };
      switch (method) {
        case 'GET':
        case 'HEAD':
          url += buildQuery(body);
          break;
        case 'POST':
        case 'PATCH':
        case 'PUT':
          if (currentHeaders['content-type'] === 'application/json') {
            data.body = JSON.stringify(body);
          } else {
            data.body = body;
          }
          break;
      }

      fetch(url, data)
        .then((response) => {
          if (!isSuccessStatus(response.status) && method !== 'HEAD') {
            console.error('Request Error @ Http Helper', response);
            hasRejected = true;
            reject(response);
          }
          responseStatus = response.status;
          return response;
        })
        .then((response) => {
          if (hasRejected) {
            return response;
          }

          if (responseStatus === 204) {
            return {};
          }

          if (useUnparsedBody) {
            return response;
          }

          const contentType = response.headers.get('content-type');
          const isJson = contentType && contentType.indexOf('application/json') !== -1;

          if (type === 'text') {
            return response.text();
          }

          return method === 'HEAD' || !isJson ? response : response.json();
        })
        .then((data) => {
          if (hasRejected) {
            return data;
          }
          // HANDLE BUSINESS LOGIC ERROR
          if (
            data.hasOwnProperty('returnCode') &&
            data.returnCode !== '0' &&
            data.returnCode !== '200'
          ) {
            console.error('BusinessLogic Error @ Http Helper', data);
            reject(data);
          } else {
            // Return data if text or array
            if (useUnparsedBody || type === 'text' || typeof data.length !== 'undefined') {
              // for text just return the data string, do not spread it out like it's being done in the else.
              setTimeout(() => resolve(data), timeout);
            } else {
              setTimeout(
                () =>
                  resolve({
                    ...data,
                    responseStatus,
                  }),
                timeout
              );
            }
          }
        })
        .catch((error) => {
          // Catching NON-HTTP Error
          console.error(`Error when calling ${method} -${url}`, error);
          reject();
        });
    });
  },

  post(url, body, headers) {
    return this.request(url, 'POST', body, headers);
  },

  get(url, body, headers) {
    return this.request(url, 'GET', body, headers);
  },

  put(url, body, headers) {
    return this.request(url, 'PUT', body, headers);
  },

  delete(url, body, headers) {
    return this.request(url, 'DELETE', body, headers);
  },
};

export default Http;

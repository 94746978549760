const COMMUNICATION_STATE_KEY = 'communication';

/**
 * Milliseconds until a notification will automatically fade,
 * unless a timeout was provided explicitly
 * @type {number}
 */
const DEFAULT_NOTIFICATION_TIMEOUT = 5000;

/**
 * Minimum milliseconds that a notification will persist unless
 * explicitly closed or FLUSH_NOTIFICATION action is fired.
 *
 * This is used to prevent notifications appearing and then immediately
 * fading, such as when notifying via an action and then redirecting
 * via a promise callback in a component.
 *
 * @type {number}
 */
const MINIMUM_NOTIFICATION_TIMEOUT = 1000;

export {
  COMMUNICATION_STATE_KEY,
  DEFAULT_NOTIFICATION_TIMEOUT,
  MINIMUM_NOTIFICATION_TIMEOUT,
};

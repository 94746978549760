export const getCookies = () => {
  return document.cookie.split(/;\s+/).reduce((cookies, cookieStr) => {
    const [key, cookie] = cookieStr.split('=');
    return { ...cookies, [key]: cookie };
  }, {});
};

export const getCookie = (name) => {
  const cookies = getCookies();
  return cookies[name];
};

// Perhaps use a different selector if we need to access the request, which hasn't happened yet
const loading = (state, key) => state.communication.loading.find(x => x.key === key);
const isLoading = (state, key) => !!loading(state, key);

const loadingByPattern = (state, pattern) => state.communication.loading.find(x => `${x.key}`.match(pattern));
const isLoadingByPattern = (state, pattern) => !!loadingByPattern(state, pattern);

const error = (state, key) => state.communication.error.find(x => x.key === key);
const hasError = (state, key) => !!error(state, key);

const errorByPattern = (state, pattern) => state.communication.error.find(x => x.key.match(pattern));
const hasErrorByPattern = (state, pattern) => !!errorByPattern(state, pattern);

const notification = (state, key) => state.communication.notification.find(x => x.key === key);

const currentNotifications = state => state.communication.notification.filter(notice => (
  !notice.expires || (notice.expires >= Date.now())
));

export {
  loading,
  isLoading,
  loadingByPattern,
  isLoadingByPattern,
  error,
  hasError,
  errorByPattern,
  hasErrorByPattern,
  notification,
  currentNotifications,
};

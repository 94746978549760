
/**
 * A method inspired by lodash's _result function.
 * When given value is a function calls the function with all additional
 * parameters and returns it's result. Otherwise just returns the value.
 *
 * @param {any|function} valueOrFunction
 * @param {any[]} args - additionaly parameters passed to function above.
 */
export default function result(valueOrFunction, ...args) {
  return typeof valueOrFunction === 'function'
    ? valueOrFunction(...args)
    : valueOrFunction;
}

import PropTypes from 'prop-types';

export * from './constants';
export * from './selectors';
export * from './actions';
export * from './actionTypes';
export { default as asyncAction } from './asyncAction';
export { default as communicationReducer } from './reducer';

export const loadingShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  request: PropTypes.object,
});

export const notificationShape = PropTypes.shape({
  message: PropTypes.string.isRequired,
  key: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isError: PropTypes.bool.isRequired,
  expires: PropTypes.number,
  props: PropTypes.object,
});

import update from 'immutability-helper';
import { CALL_HISTORY_METHOD, LOCATION_CHANGE } from 'react-router-redux';
import _omit from 'lodash/omit';
import _reject from 'lodash/reject';
import * as actions from './actionTypes';
import { MINIMUM_NOTIFICATION_TIMEOUT } from './constants';

const initialState = {
  loading: [],
  error: [],
  notification: [],
};

const communication = (state = initialState, action) => {
  let index;
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        loading: update(state.loading, {
          $push: [{ key: action.key, loading: true, request: action.request }],
        }),
      };
    }
    case actions.HAS_LOADED: {
      index = state.loading.findIndex(x => x.key === action.key);
      if (index === -1) {
        return state;
      }
      return {
        ...state,
        loading: update(state.loading, {
          $splice: [[index, 1]],
        }),
      };
    }
    case actions.ADD_ERROR: {
      return {
        ...state,
        error: update(state.error, {
          $push: [{ key: action.key, error: action.error }],
        }),
      };
    }
    case actions.CLEAR_ERROR: {
      index = state.error.findIndex(x => x.key === action.key);
      if (index === -1) {
        return state;
      }
      return {
        ...state,
        error: update(state.error, {
          $splice: [[index, 1]],
        }),
      };
    }
    case actions.CLEAR_ERRORS: {
      return update(state, {
        error: { $set: initialState.error },
      });
    }
    case actions.CLEAR_ERRORS_BY_PATTERN: {
      const pattern = new RegExp(action.pattern);

      return update(state, {
        error: {
          $set: _reject(state.error, ({ key }) => pattern.test(key)), 
        },
      });
    }
    case actions.ADD_NOTIFICATION: {
      return {
        ...state,
        notification: update(state.notification, {
          $push: [_omit(action, ['type'])],
        }),
      };
    }
    case actions.REMOVE_NOTIFICATION: {
      index = state.notification.findIndex(x => x.key === action.key);
      if (index === -1) {
        return state;
      }
      return {
        ...state,
        notification: update(state.notification, {
          $splice: [[index, 1]],
        }),
      };
    }
    case LOCATION_CHANGE:
    case CALL_HISTORY_METHOD: {
      const recentNotifications = state.notification.filter((notification) => (
        (Date.now() - notification.created) <= MINIMUM_NOTIFICATION_TIMEOUT
      ));
      return {
        ...state,
        notification: update(state.notification, {
          $set: recentNotifications,
        }),
      };
    }
    case actions.FLUSH_NOTIFICATIONS: {
      return {
        ...state,
        notification: [],
      };
    }
    default:
      return state;
  }
};

export default communication;

const isBetween = (x, min, max) => x >= min && x <= max;

/**
 * Returns true if status code provided is a status in the 2xx range.
 * @param {number} code response.status code
 * @returns {boolean}
 */
const isSuccessStatus = (code) => {
  return isBetween(code, 200, 299);
};

export default isSuccessStatus;

import * as actions from './actionTypes';
import { DEFAULT_NOTIFICATION_TIMEOUT } from './constants';

/**
 * @param key String that can be used to identify what is loading
 * @param request Optionally set the promise of the request
 */
export const load = (key, request) => {
  let action = {
    type: actions.LOAD,
    key,
  };
  if (request) {
    action.request = request;
  }

  return action;
};

/**
 * @param key String that can be used to identify what is no longer loading
 */
export const hasLoaded = (key) => ({
  type: actions.HAS_LOADED,
  key,
});

/**
 *
 * @param key String that can be used to identify what is/isn't in an error state
 * @param error Can set either an error message or an object describing the error state
 */
export const addError = (key, error) => ({
  type: actions.ADD_ERROR,
  key,
  error,
});

/**
 *
 * @param key String that can be used to identify what is/isn't in an error state
 * @param message Optionally specify an error message. If specified, this will be used instead of a boolean value
 */
export const clearError = (key) => ({
  type: actions.CLEAR_ERROR,
  key,
});

export const clearErrors = () => ({
  type: actions.CLEAR_ERRORS
});

/**
 * @param {RegExp|string} pattern - All errors with key matching this pattern shall be removed.
 */
export const clearErrorsByPattern = (pattern) => ({
  type: actions.CLEAR_ERRORS_BY_PATTERN,
  pattern,
});

/**
 * @private
 */
const addNotificationAction = (message, key, isError, created, expires, props) => ({
  type: actions.ADD_NOTIFICATION,
  message,
  key,
  isError,
  created,
  expires,
  props,
});

const flushNotifications = () => ({
  type: actions.FLUSH_NOTIFICATIONS,
});

export const removeNotification = key => ({
  type: actions.REMOVE_NOTIFICATION,
  key,
});

export const addNotification = (message, options = {}) => {
  const timestamp = Date.now();
  const {
    flush = false,
    isError = false,
    timeout = DEFAULT_NOTIFICATION_TIMEOUT,
    key = timestamp,
    props,
    ...otherProps
  } = options;

  return (dispatch) => {
    if (flush) {
      dispatch(flushNotifications());
    }
    const expires = timeout ? timestamp + timeout : null;
    dispatch(addNotificationAction(message, key, isError, timestamp, expires, { ...props, ...otherProps }));
    if (timeout) {
      setTimeout(() => dispatch(removeNotification(key)), timeout);
    }
  };
};

export const addSuccessNotification = addNotification;
export const addErrorNotification = (message, options) => (
  addNotification(message, { ...options, isError: true })
);
